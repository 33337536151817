import React from 'react';
import styled from 'styled-components';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

export default function Bank() {
  let bankList = 'Alta Bank,America First Credit Union,BMO/Bank of the West,Bank of Utah,Bonneville Bank,Brighton Bank,Cache Valley Bank,Capital Community Bank,Central Bank,Chase Bank,Cyprus Credit Union,DL Evans ,First Community Bank,First Utah Bank,Fortis Bank,Goldenwest Credit Union,Grand Valley Bank,Hillcrest Bank,Key Bank,MidFirst Bank,Mountain America Credit Union,Prime Alliance,State Bank of Southern Utah,Sunwest Bank,Umpqua Banknom,US Bank,Utah First Credit Union,WaFd Bank/Washington Federal ,Wells Fargo,Zions Bank'
  let bankListArray = bankList.split(',')
  return (
    <Main>
        <Title>UTAH IOLTA-ELIGIBLE FINANCIAL INSTITUTIONS</Title>
        <List sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            height: '550px',
            width: '30%',
            '@media (max-width: 768px)': {
              width: '50%',
              height: '900px'
            }
            
        }}>
            {bankListArray.map((bank, index) => {
              <ListItem key={index} disablePadding ></ListItem> //No idea why this fixes it
                return (
                    <ListItem key={index} disablePadding >
                    <ListItemButton>
                      <ListItemText primary={bank} />
                    </ListItemButton>
                  </ListItem>
                )
            })}
        </List>
    </Main>
  );
}

const Main = styled.div`
  width: 93%;
  margin: auto;
  padding: 0rem 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: white;
`;

const Title = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  font-size: 2rem;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 15px;
  }
`;
