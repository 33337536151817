import React from 'react';
import styled from 'styled-components';
import Photo from './photo';

import elaina from '../images/Elaina.jpg';
import marji from '../images/Marji.webp';
import nicole from '../images/Nicole.jpg';
import snow from '../images/Snow.jpg';
import tim from '../images/Tim.jpg';
import walt from '../images/Walt.jpg';
import michael from '../images/Michael.jpg';
import kim from '../images/kim.jpg';

export default function About() {
  var memberData = [
    
    {
      name: 'Timothy M. Wheelwright',
      position: 'President',
      info: 'Dentons',
      photo: tim,
    },
    {
      name: 'Elaina M. Maragakis',
      position: 'Vice-President',
      info: 'Ray Quinney & Nebeker',
      photo: elaina,
    },
    {
      name: 'V. Lowry Snow',
      position: 'Secretary/Treasurer',
      info: ' Snow, Jensen & Reece',
      photo: snow,
    },
    {
      name: 'Walter A. Romney, Jr',
      info: 'Clyde Snow',
      photo: walt,
    },
    {
      name: 'Marji Hanson',
      info: 'Retired Bankrupcy Attorney',
      photo: marji,
    },
    {
      name: 'Nicole Salazar-Hall',
      info: 'Parsons Behle & Latimer',
      photo: nicole,
    },
    {
      name: 'Michael Thomson',
      info: 'Greenberg Traurig',
      photo: michael,
    },
    {
      name: 'Kim Paulding',
      info: 'Executive Director',
      photo: kim
    },
  ];

  return (
    <Main>
      <Title>About</Title>
      <TopInfo>
        <Contact>
          Utah Bar Foundation
          <br />
          645 South 200 East
          <br />
          Salt Lake City, Utah 84111
          <br />
          Tel: <Link href="tel:8012977046">801-297-7046</Link>
          <br />
          Email: <Link href="mailto:kim@utahbarfoundation.org">kim@utahbarfoundation.org</Link>
        </Contact>
        <TextBox>
          The Utah Bar Foundation is a nonprofit 501(c)3 charitable organization
          that is a completely separate entity from the Utah State Bar.
          <br />
          <br />
          The Utah Bar Foundation was organized in 1963 with a mission to:
          <UnorderedList>
            <li>Promote legal education and increase the knowledge and awareness of
              the law in Utah; </li>

            <li>Assist in providing legal services to lower income
              Utahns; </li>
            <li>Improve the administration of justice; and </li>
            <li>Serve other
              worthwhile law-related public purposes </li>
          </UnorderedList>
          The main source of revenue to
          achieve our mission comes from administering the Utah Supreme Court’s
          IOLTA Program. Since the creation of the IOLTA Program, the Utah Bar
          Foundation has been able to provide more than $6 million in grants.
        </TextBox>
      </TopInfo>
      <BoardOfDirectors>
        <div style={{ fontSize: '1.5rem', padding: '10px' }}>
          The Utah Bar Foundation is governed by a seven-member Board of
          Directors.
        </div>
        <Photos>
          {memberData.map((member) => {
            return <Photo data={member} key={member.name} />;
          })}
        </Photos>
        <Bio></Bio>
      </BoardOfDirectors>
      <TextBox>
        <Body></Body>
      </TextBox>
    </Main>
  );
}

const Link = styled.a`
text-decoration: none;
color: black
`

const Main = styled.div`
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Title = styled.div`
  background-color: #8f7c71;
  padding: 1rem;
  border-radius: 30px 0 0 0;
  margin-left: 5rem;
  font-size: 2rem;
  color: white;
  border-right: 15px solid #9ad9ac;
`;

const TextBox = styled.div`
  box-sizing: border-box;
  padding: 10px;
  margin: 1% 5%;
  font-size: 1.2rem;
`;

const Body = styled.p`
  margin: 0px;
  text-align: center;
`;
const TopInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6rem;

  @media (max-width: 768px) {
  flex-direction: column;
  margin-left: 0rem;
  }
`;

const Contact = styled.div`
background-color: #8f7c716c;
padding: 15px;
border-radius: 5px;
margin: 5px;
`;

const BoardOfDirectors = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Photos = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Bio = styled.div``;

const UnorderedList = styled.ul`
  margin: 10px;
  font-size: 1.2rem;
`;
